<style scoped>
  .text-danger {
    color: red !important;
  }
</style>
<template>
    <!-- HORIZONTAL LAYOUT -->
    <div class="vx-row">
        <div class="vx-col sm:w-1/2 w-full mb-base">
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/6 w-full">
                    <span>Budget Code</span>
                </div>
                <div class="vx-col sm:w-5/6 w-full">
                    <vs-input class="w-full" v-model="budget.Code" disabled/>
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/6 w-full">
                    <span>Type</span>
                </div>
                <div class="vx-col vx-row sm:w-5/6 w-full">
                    <div class="vx-col sm:w-1/6">
                        <input type="radio" value="1" v-model="budget.Type">
                        <label> Quantity</label>
                    </div>
                    <div class="vx-col sm:w-1/6">
                        <input type="radio" value="2" v-model="budget.Type">
                        <label> Value</label>
                    </div>
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/6 w-full">
                    <span>Value</span>
                </div>
                <div class="vx-col sm:w-5/6 w-full">
                    <vs-input class="w-full" v-model="budget.Value"/>
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/6 w-full">
                    <span>Description</span>
                </div>
                <div class="vx-col sm:w-5/6 w-full">
                    <vs-textarea maxlength ="255" class="w-full" :label="charactersLeft" name="Description" v-model="budget.Description" />
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/6 w-full">
                    <span>Valid from</span>
                </div>
                <div class="vx-col sm:w-5/6 w-full">
                    <datepicker :inline="false" v-model="budget.ValidFrom" placeholder="Select valid from"></datepicker>
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/6 w-full">
                    <span>Valid to</span>
                </div>
                <div class="vx-col sm:w-5/6 w-full">
                    <datepicker :inline="false" v-model="budget.ValidTo" placeholder="Select valid to"></datepicker>
                </div>
            </div>   
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/6 w-full">
                    <span>Currency</span>
                </div>
                <div class="vx-col sm:w-5/6 w-full">
                    <multiselect
                            class="selectExample"
                            v-model="selectedCurrency"
                            :options="optionCurrencyCode"
                            :multiple="false"
                            :allow-empty="false"
                            :group-select="false"
                            :max-height="120"
                            :limit="3"
                            placeholder=" Type to search"
                            track-by="Code"
                            label="Code"
                            :disabled="false"
                        >
                        <template slot="singleLabel" slot-scope="dt">
                            <span class="option__desc">
                                <span class="option__title">{{ dt.option.Code }}</span>
                            </span>
                        </template>

                        <template slot="option" slot-scope="dt">
                            <div class="option__desc">
                                <span class="option__title">{{ dt.option.Code }}</span>
                            </div>
                        </template>
                    </multiselect>
                </div>
            </div>  
            <br>
        </div>
        <div class="vx-col sm:w-1/2 w-full mb-base">
        </div>
        <hr>
        <br>
        <div class="vx-col w-full mb-base">
            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <vs-table :data="budget.BudgetLine" stripe style="width:100%">
                        <template slot="thead">
                            <vs-th>No</vs-th>
                            <vs-th>Source</vs-th>
                            <vs-th>Owner</vs-th>
                            <vs-th>Percentage (%)</vs-th>
                            <vs-th>Value</vs-th>
                        </template>

                        <template slot-scope="{data}">
                            <vs-tr :key="index" v-for="(dt, index) in data">
                                <vs-td style="align-item-right">{{index+1}}</vs-td>
                                <vs-td style="align-item-center">{{ (dt.Type == 1) ? 'internal' : 'external' }}</vs-td>
                                <vs-td style="align-item-center">nama account</vs-td>
                                <vs-td>{{ dt.Percentage }}</vs-td>
                                <vs-td>
                                    <vue-number-input
                                        inline
                                        controls
                                        :min="0"
                                        :step="1"
                                        size="small"
                                        v-model="dt.Value"
                                        :value="0"
                                        @keypress="isNumber($event)"
                                    />
                                </vs-td>
                            </vs-tr>
                        </template>
                    </vs-table>
                </div>
            </div>

            <div class="vx-row">
                <div class="vx-col sm:w-6/12 w-full">
                    <vs-button class="mr-3 mb-2" v-on:click="SubmitForm()">Submit</vs-button>
                    <!-- <vs-button v-if="update" color="warning" class="mr-3 mb-2" @click="ResetForm()">Reset</vs-button> -->
                    <vs-button v-if="update" color="danger" class="mr-3 mb-2" @click="CancelForm()">Cancel</vs-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import VueNumberInput from "@chenfengyuan/vue-number-input";
import flatPickr from 'vue-flatpickr-component';
import ConfirmDatePlugin from 'flatpickr/dist/plugins/confirmDate/confirmDate.js';
import 'flatpickr/dist/flatpickr.css';
import fieldPromotionCode from '../component/field_promotion_code.vue'
import datatablePromotionCode from "../component/datatable_promotion_code.vue";
// import fieldReservationCode from '../component/field_reservation_code.vue'
// import datatableReservationCode from "../component/datatable_reservation_code.vue";
// import moment from 'moment'

export default {
    props: ["territoryCode", "territoryId", "selectedBudget", "optionTerritory"],
    components: {
        Datepicker,
        VueNumberInput,
        flatPickr,
        fieldPromotionCode,
        datatablePromotionCode,
        // fieldReservationCode,
        // datatableReservationCode
    },
    mounted(){
        // console.log("userLogin : ", this.$userLogin);
        if(this.selectedBudget && this.selectedBudget.Code) {
            this.resetBudget = Object.assign({}, this.selectedBudget);
            this.budget = this.selectedBudget;
            
            this.budget.ValidFrom = new Date(this.budget.ValidFrom)
            this.budget.ValidTo = new Date(this.budget.ValidTo)

            this.update = true;
            this.selectedCurrency = {
                ID: 0,
                Code: this.budget.CurrencyCode,
            }
        } else {
           this.setDefaultDate();
           this.resetBudget = Object.assign({}, this.budget);
        }

        this.getOptionCurrencyCode();
    },
    computed: {
        DueDate: {
            get() {
                var d = new Date()
                if(this.selectedBudget && this.selectedBudget.DueDate){
                    d = new Date(this.selectedBudget.DueDate)
                    d.setHours(d.getHours() - 7)
                } else {
                    d = new Date(this.budget.DueDate)
                }
                return d
            },
            set(val) {
                this.DueDatePost = val
                return val
            }
        },
        charactersLeft() {
            var char = this.budget.Description.length,
                limit = 255;

            return "counter " + (limit - char);
        },
        currentPage: {
            get() {
                return 1;
            },
            set(page) {
                return page;
            }
        },
    },
    data: () => ({
        configFlatPickrDueDate: {
            // wrap: true, // set wrap to true only when using 'input-group'
            altFormat: 'F j, Y',
            // altInput: true,
            dateFormat: 'j F Y H:i',
            enableTime: true,
            minTime: "00:00",
            plugins: [new ConfirmDatePlugin()]
        },

        configFlatPickrDate: {
            // wrap: true, // set wrap to true only when using 'input-group'
            altFormat: 'F j, Y',
            // altInput: true,
            dateFormat: 'j F Y',
            enableTime: false,
        },

        hour: "",
        minute: "",

        update: false,

        selectedCurrency:{},

        resetBudget: {},
        budget: {
            Code: "",
            Description: "",
            Type: 1,
            ValidFrom: "",
            ValidTo: "",
            CurrencyCode: "",
            Value: 0,
            BudgetLine: [
                {
                    Type: 1,
                    Account: null,
                    Percentage: 0,
                    Value: 0
                },
                {
                    Type: 2,
                    Account: null,
                    Percentage: 0,
                    Value: 0
                }
            ]
        },

        optionCurrencyCode: [{}],

        limits: [10],
        limitShow: 10,
        drawData: 0,
        isActive: 1,
        search: "",
        recordsTotal: 0,
        maxPage: 2,
        dataEnd: 0,
    }),
    methods: {
        checkDate() {
            let validFrom = this.budget.ValidFrom
            let validTo = this.budget.ValidTo

            if(validFrom.getFullYear() > validTo.getFullYear()) {
                console.log(validFrom.getFullYear(), validTo.getFullYear())
                return false;
            }

            if(validFrom.getMonth() > validTo.getMonth()) {
                console.log(validFrom.getMonth(), validTo.getMonth())
                return false;
            }

            if(validFrom.getMonth() == validTo.getMonth() && validFrom.getDate() > validTo.getDate()) {
                console.log(validFrom.getDate(), validTo.getDate())
                return false;
            }

            return true
        },
        scrollToTop() {
            window.scrollTo(0,0);
        },
        formatCurrency(nominal) {
            if(nominal && nominal > 0) { // nominal > 0 untuk handle return NaN
                nominal = (nominal/1).toFixed(2).replace('.', ',');
                return nominal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            } else {
                return 0;
            }
        },
        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        containsKey(obj, key ) {
            return Object.keys(obj).includes(key);
        },
        ResetForm() {
            this.budget = Object.assign({}, this.resetBudget);
        },
        CancelForm() {
            this.update = false;
            this.resetBudget = {
                Code: "",
                Description: "",
                Type: 1,
                ValidFrom: "",
                ValidTo: "",
                CurrencyCode: "",
                Value: 0,
                BudgetLine: [
                    {
                        Type: 1,
                        Account: null,
                        Percentage: 0,
                        Value: 0
                    },
                    {
                        Type: 2,
                        Account: null,
                        Percentage: 0,
                        Value: 0
                    }
                ]
            };

            this.budget = Object.assign({}, this.resetBudget);
            this.selectedCurrency = {};
            
            this.$emit("selectBudget", {});
        },
        changeLimit(limit) {
            this.limitShow = limit;
        },
        changePrice() {
            this.calculated = false;
        },
        changeCharge() {            
            this.calculated = false;
        },
        getOptionCurrencyCode() {
            this.$vs.loading();

            this.$http.get("/api/v1/master/currency", {
                params: {
                    order: 'code',
                    sort: 'asc',
                }
            }).then(resp => {
                if (resp.code == 200) {
                    this.optionCurrencyCode = resp.data.records;
                    if(this.budget.CurrencyCode == 0 || this.budget.CurrencyCode == null) {
                        this.selectedCurrency = {
                            ID: this.optionCurrencyCode[0].ID,
                            Code: this.optionCurrencyCode[0].Code,
                        };
                    } else {
                        for (let index = 0; index < this.optionCurrencyCode.length; index++) {
                            if(this.optionCurrencyCode[index].code == this.budget.CurrencyCode) {
                                this.selectedCurrency = {
                                    ID: this.optionCurrencyCode[index].ID,
                                    Code: this.optionCurrencyCode[index].Code,
                                };
                                break;
                            }   
                        }
                    }
                } else {
                    this.$vs.notify({
                        title: "Error",
                        text: "Failed to get Currency option",
                        color: "danger",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-check"
                    });
                }
                this.$vs.loading.close();
            });
        },

        setParam() {
            let param = {
                code: this.budget.Code,
                currency_code: this.selectedCurrency.Code,
                description: this.budget.Description,
                valid_from: this.budget.ValidFrom,
                valid_to: this.budget.ValidTo,
                type: parseInt(this.budget.Type),
                value: parseFloat(this.budget.Value),
                budget_line: this.budget.BudgetLine,
            }
            console.log("param : ", param)
            return param
        },
        
        SubmitForm() {
            if(this.budget.ValidFrom == "" || this.budget.ValidFrom == 0 || this.budget.ValidFrom == null) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select valid from first",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check"
                });
                return true;
            }

            if(this.budget.ValidTo == "" || this.budget.ValidTo == 0 || this.budget.ValidTo == null ) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select valid to first",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check"
                });
                return true;
            }

            if(!this.checkDate()) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select valid to Date must be equal or after valid from",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check"
                });
                return true;
            }

            if(this.selectedCurrency.Code == "" || this.selectedCurrency.Code == 0 || this.selectedCurrency.Code == null) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select Currency",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check"
                });
                return true;
            }

            this.$vs.loading();
            this.$http
            .post("/api/v1/promotion-budget/store", this.setParam())
            .then(resp => {
                // console.log(resp);
                if (resp.code == 200) {
                    this.$vs.notify({
                        title: "Success",
                        text: "Promotion Budget has been saved",
                        color: "success",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-check"
                    });
                    this.CancelForm();
                    this.setDefaultDate();
                } else {
                    this.$vs.notify({
                        title: "Error",
                        text: resp.message,
                        color: "danger",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-check"
                    });
                }
                this.$vs.loading.close();
            });
        },
        setDefaultDate() {
            var day = new Date();
            var nextDay = new Date(day);
            nextDay.setDate(day.getDate() + 1); 

            this.budget.Date = day
            this.budget.DueDate = nextDay
        },
        getTime(id) {
            this.$vs.loading();
            this.$http.get("/api/v1/master/customer/work-time/"+id).then(resp => {
                if (resp.code == 200) {
                   var day = new Date();
                    var nextDay = new Date(day);
                    nextDay.setDate(day.getDate() + 1);
                    nextDay.setHours(resp.data[0])
                    nextDay.setMinutes(resp.data[1])
                    this.budget.DueDate = nextDay
                }
                this.$vs.loading.close();
            });
        },
    },
};
</script>
