<template>
    <div>
        <!-- main page -->
        <vs-tabs ref="sub-tabs" alignment="center">
            <vs-tab label="New">
                <div class="con-tab-ejemplo">
                    <!-- <datatable :progress="''" :progressRestriction="false" :territory="territory.code" :salesChannel="salesChannel.code" :customerCategory="customerCategory.ID" v-on:clickEdit="clickEdit"></datatable> -->
                    <datatable :progress="''" :progressRestriction="true" v-on:clickEdit="clickEdit"></datatable>
                </div>
            </vs-tab>
            <div v-if="false">
                <vs-tab label="Validated">
                    <div class="con-tab-ejemplo">
                        <!-- <datatable :progress="'validated'" :progressRestriction="true" :territory="territory.code" :salesChannel="salesChannel.code" :customerCategory="customerCategory.ID"></datatable> -->
                        <datatable :progress="'validated'"></datatable>
                    </div>
                </vs-tab>
                <vs-tab label="Approved">
                    <div class="con-tab-ejemplo">
                        <!-- <datatable :progress="'approved'" :progressRestriction="true" :territory="territory.code" :salesChannel="salesChannel.code" :customerCategory="customerCategory.ID"></datatable> -->
                        <datatable :progress="'approved'" :progressRestriction="true"></datatable>
                    </div>
                </vs-tab>
                <vs-tab label="Rejected">
                    <div class="con-tab-ejemplo">
                        <!-- <datatable :progress="'rejected'" :progressRestriction="true" :territory="territory.code" :salesChannel="salesChannel.code" :customerCategory="customerCategory.ID" v-on:clickEdit="clickEdit"></datatable>-->
                        <datatable :progress="'rejected'" :progressRestriction="true" v-on:clickEdit="clickEdit"></datatable>
                    </div>
                </vs-tab>
                <vs-tab label="Canceled">
                    <div class="con-tab-ejemplo">
                        <!-- <datatable :isCancel="1" :territory="territory.code" :salesChannel="salesChannel.code" :customerCategory="customerCategory.ID"></datatable> -->
                        <datatable :isCancel="1"></datatable>
                    </div>
                </vs-tab>
                <vs-tab label="Released">
                    <div class="con-tab-ejemplo">
                        <!-- <datatable :progress="'released'" :progressRestriction="true" :territory="territory.code" :salesChannel="salesChannel.code" :customerCategory="customerCategory.ID"></datatable> -->
                        <datatable :progress="'released'" :progressRestriction="true"></datatable>
                    </div>
                </vs-tab>
            </div>
        </vs-tabs>
    </div>
</template>


<script>
import datatable from "./datatable.vue";

export default {
    components: {
        datatable
    },
    props: 
        ["territory", "salesChannel", "customerCategory"],
        data() {
            return {
                // 
            };
        },
    watch: {
        // 
    },
    mounted() {
        // 
    },
    computed: {
        // 
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0,0);
        },

        clickEdit(budget) {
            this.$emit("selectBudget", budget);
            this.$emit("redirectTab", 0);
        },
    }
};
</script>

<style>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
</style>